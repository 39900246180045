@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 10px;
    font-family: 'Andada Pro', sans-serif;
    overflow-x: hidden;
}

body {
    font-size: 1.8rem;
    background-color: white;
    color: #000000;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

@media (max-width: 1024px) {
    body {
        font-size: 1.6rem;
    }
}
