.footer-container {
  padding: 50px 60px;
  background: #E2E6E5;

  @media (max-width: 1024px) {
    padding: 25px 15px;
  }
}

.footer-title {
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

.footer-text {
  font-size: 22px;
  font-weight: 500;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}


@media (max-width: 1024px) {
  .img-mobile {
    height: 45px;
    width: 45px;
  }
}

.image-container:hover a img {
  content: url('../../../public/images/podpis_kolorowy.png');
}

.btn-up {
  position: fixed;
  top: 85%;
  right: 15px;
  padding: 16px 12px;
  background: rgba(235, 102, 17, 0.8);
  border-radius: 25px;
  z-index: 1000;
}
