.divorces-title {
  color: #EB6611;
  font-size: 24px;
  font-weight: 600;
}

.scope {
  list-style: disc;
  padding-left: 20px;

  li {
    margin-bottom: 10px;
  }
}

.know-more {
  color: white;
  background: #EB6611;
  padding: 13px 70px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 2px solid #EB6611;
}

.know-more:hover {
  color: #EB6611;
  background: white;
}