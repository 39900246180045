.btn-nav {
  background: #E2E6E5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 16px
  }
}

.btn-nav.active-color{
  background: #EB6611;
  color: white;
}

.btn-nav.active-color img{
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(240deg) brightness(105%) contrast(107%);
}

.btn-nav:hover {
  color: white;
  background: #EB6611;
}

.btn-nav:hover img {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(240deg) brightness(105%) contrast(107%);
}

.specialization-title {
  font-size: 22px;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
}

.btn-spec {
  padding: 13px 48px;
  font-size: 24px;
  font-weight: 600;
  background: #535353;
  border-radius: 20px;
  color: white;
}

.btn-spec:hover {
  background: #EB6611;
  color: white;
}