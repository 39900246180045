.user-details {
  padding: 40px 48px 80px;

  @media (max-width: 1024px) {
    padding: 25px 15px;
  }
}

.user-wrapper {
  padding: 30px 100px 100px;

  @media (max-width: 1024px) {
    padding: 25px 0;
  }
}

.box-wrapper {
  background: rgba(222, 227, 226, 0.9);
  padding: 35px 0 55px;
  width: 100%;
  max-width: 640px;
  max-height: 650px;

}

.box-name {
  font-size: 26px;
  font-weight: 600;
  white-space: nowrap;
}

.box-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: justify;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.switcher {
  padding: 40px 100px 20px;
  display: flex;
  flex-wrap: wrap;


  @media (max-width: 1024px) {
    padding: 25px 15px;
    overflow-x: scroll;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  a {
    font-size: 20px;
    font-weight: 500;
    margin: 0 20px 10px;


    @media (max-width: 1024px) {
      font-size: 16px;
      margin-right: 20px;
      white-space: nowrap;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  a:hover {
    color: #EB6611;
  }

  ul{
    list-style: none;
  }
}

.active-user {
  color: #EB6611;
}

.btn-home:hover {
  color: #eb6611;
}