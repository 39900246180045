.page-wrapper {
  padding: 50px 35px;

  @media (max-width: 1024px) {
    padding: 30px 15px;
  }
}

.page-title {
  font-size: 32px;
  font-weight: 600;
  padding: 0 5px;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
}

.find-us-title {
  font-size: 20px;
  font-weight: 500;
  margin-right: 60px;
}

.find-us-wrapper {
  a {
    color: #EB6611;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 0;
    border-bottom: 2px solid transparent;

    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }
  a:hover {
    border-bottom: 2px solid #EB6611;
    }

  span {
    font-size: 20px;
    font-weight: 500;
    margin-right: 60px;

    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }
}

.contact-title {
  color: #EB6611;
  font-size: 36px;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
}

.contact-link {
  font-size: 22px;
  font-weight: 600;
  color: #EB6611;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

.contact-link:hover {
  border-bottom: 2px solid #EB6611;
}

.contact-text {
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}