.main-page-container {
}

.slider-container {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #EAEAEA 11.8%, #CAD3D2 100%);

  @media (max-width: 1024px) {
    padding: 15px 0;
  }
}

.description-title {
  text-align: center;
  font-size: 80px;
  font-weight: 700;
  color: #EB6611;

  @media (max-width: 1024px) {
    font-size: 32px;
  }
}

.description-text {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #313131;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

.slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide--current {
  opacity: 1;
}

.slide--hidden {
  display: none;
}

.team-wrapper {
  padding: 90px 80px 0 80px;

  @media (max-width: 1024px) {
    padding: 25px 15px;
  }
}

.team-title {
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 5px;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
}

hr {
  border: 1.5px solid #EB6611;
  width: 100%;
}

.cards-wrapper {
  padding: 100px 15px 60px 15px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 25px 15px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.card-wrapper {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.card-name {
  position: relative;
  background: rgba(222, 227, 226, 0.9);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 32px;

  @media (max-width: 1024px) {
    width: 288px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

.prof-title {
  font-size: 14px;
  font-weight: 400;
  color: #6F6F6F;
  margin-bottom: 25px;
}

.card-contact {
  color: #313131;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 20px;
}

.btn-more {
  color: #EB6611;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  padding: 0 5px;
}

.trust-wrapper {
  padding: 15px 45px 30px;
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    padding: 15px;
    margin-bottom: 40px;
  }

  .image-logo {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}



.slick-dots {
  bottom: 35px !important;
}

.slick-dots li button:before {
  font-size: 17px !important;

}

.slick-dots li.slick-active button:before {
  color: #EB6611 !important;
}