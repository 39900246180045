.header-container {
  padding: 25px 45px;
  background: #e2e6e5;
  height: 120px;
  
  @media (max-width: 1024px) {
    padding: 14px;
    height: 60px;
  }
}

.mobile-menu {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100vw;
  padding: 35px 20px 15px;
  background: rgba(234, 234, 234, 0.9);

  ul {
    display: flex;
    flex-direction: column;
    li {
      margin-bottom: 15px;
    }
  }
}

a {
  cursor: pointer;
}

a:hover {
  color: #EB6611;
}

nav {
  display: flex;
  align-items: center;
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li{
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 20px;
  font-weight: 600;
  color: #313131;
}

nav ul li.group{
  padding: 0 40px;
}

nav ul li.group:last-child{
  padding: 0 0 0 40px
}

.group p{
  cursor: pointer;
}

.group p:hover {
  color: #EB6611;
}


.vl {
  display: flex;
  align-items: center;
  border: 1.5px solid #313131;
  height: 14px;
  position: relative;
  top: 7px;
}

.group ul{
  top: 25px;
  left: 0;
  background: #e2e6e5;
  padding: 60px 34px 0 34px;

  > li a{
    font-size: 18px;
    font-weight: 600;
  }
}

.active {
  color:#EB6611;
}